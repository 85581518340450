

(function () {
  
    "use strict";

    class ExpensesComponent extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, $, $rootScope, ModalService, CompanyService, AnalyticsService, PlaidLinkService,
                    NavigationService, PopupMessageService, FeaturesService, ToastService, CompaniesManager, ReactModalService,
                    UsersManager, AppStates, FeatureRestrictionService, moment, $translate, $timeout, PendingTasksManager) {
            super($scope, $injector);
            this.__objectType = 'ExpensesController';
    
        this.moment = moment;
        this.ModalService = ModalService;
        this.CompanyService = CompanyService;
        this.PopupMessageService = PopupMessageService;
        this.ToastService = ToastService;
        this.NavigationService = NavigationService;
        this.FeaturesService = FeaturesService;
        this.CompaniesManager = CompaniesManager;
        this.UsersManager = UsersManager;
        this.PendingTasksManager = PendingTasksManager;
        this.company = CompaniesManager.getCurrCompany();
        this.AnalyticsService = AnalyticsService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.ReactModalService = ReactModalService;
        this.$timeout = $timeout;
        this.$ = $;
        AnalyticsService.trackPageView(this, 'expenses');
        this.downloadCsvText = 'REPORTS.ANALYTICS.SUMMARY._DOWNLOAD_LINK_';
        this.inProgressText = 'REPORTS.ANALYTICS._PREPARING_';
        this.csvLinkText = this.downloadCsvText;

        this.sortColumn = 'expense_date';
        this.sortDirectionReverse = false;

        this.filters = [
            {value: '30_days', text: $translate.instant('EXPENSES.FILTER._30_DAYS_')},
            {value: '90_days', text: $translate.instant('EXPENSES.FILTER._90_DAYS_')},
            {value: 'custom', text: $translate.instant('EXPENSES.FILTER._CUSTOM_')},
        ];
        this.curFilter = this.filters[0];
        this.showDateRange = false;
        this.filterStartDate = null;
        this.filterEndDate = null;
        
        var today = new Date(Date.now());
        var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

        this.apiDateFormat = 'YYYY-MM-DD';

        this.perPage = 50;
        this.pageNum = 1;

        this.hasMoreExpenses = false;
        this.newCreatedExpense = false;
        this.isExpensesPage = true;

        this.currUser = this.UsersManager.getCurrUser();
        this.showImportExpenses = this.currUser.isFeatureActive(FeaturesService.rolloutFeatures.expenses_import);
        this.isBookkeeperMode = this.currUser.isBookkeeperMode();

        this.expenseItems = [];
        this.manualExpenseItems = [];
        this.accounts = null;
        this.showEmptyState = false;
        this.loadingExpenses = true;
        this.refreshExpenses();
        this.restrictedFeature = this.FeatureRestrictionService.features.expenses;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
        this.showRestrictedEmptyState = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

        const unsubscribeFeatureRestrictions = $scope.$watch('expensesVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
            this.refreshExpenses();
        }.bind(this));

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
        });

        const redirectData = PlaidLinkService.loadRedirectData();

        if (redirectData.isRedirect) {
            this.handleClickImportExpenses(true);
        }

    }


        handleClickCreateNewExpense() {
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }

            this.CompaniesManager.createExpense(this.company).then(function success(resp) {
                    this.showEmptyState = false;
                    resp.data.source = 'Manual';
                    this.expenseItems.unshift(resp.data);
                    this.editModeNewExpense();
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_expense_creates);
                }.bind(this),
                function error() {

                });
        }

        editModeNewExpense(){
            this.newCreatedExpense = true;
        }

        handleClickShowMore() {
            this.refreshExpenses(true);
        }

        handleClickImportExpenses(isOnRedirect) {
            this.ModalService.openConnectBankAccountModal('expenses', 'WHY_PLAID', true, { isOnRedirect: isOnRedirect  })
                .then(function onSuccess(accounts) {
                        if(accounts && accounts.length > 0) {
                            this.AnalyticsService.trackSuccess(this, 'successfully marked account(s) as business',
                                {numOfAccounts: accounts.length});
                            this.getTransactions();
                        } else {
                            this.AnalyticsService.trackSuccess(this, 'close connect bank account modal');
                        }
                }.bind(this)
                ).catch(function onFailure(error) {
                    if(error) {
                        this.AnalyticsService.trackError(this, 'failed to mark account(s) as business', error);
                    }
                }.bind(this));
        }
        
        handleClickColumnSort(column) {
            if (this.sortColumn === column) {
                this.sortDirectionReverse = !this.sortDirectionReverse;
            } else {
                this.sortColumn = column;
                this.sortDirectionReverse = false;
            }
            this.refreshExpenses();
        }

        onExpenseDelete(expense){
            this.expenseItems = this.expenseItems.filter(function(item) {
                return item._id !== expense._id;
            });
            if(!this.expenseItems || this.expenseItems.length < 1) {
                this.showEmptyState = true;
                this.showRestrictedEmptyState = this.isFeatureRestricted;
            }
        }
        getTransactions() {
            this.readingTransactions = true;
            var pendingTaskPromise = this.PendingTasksManager.createPendingTask(
                this.PendingTasksManager.pendingTasksTypes.plaidReadTransactions, {user_id: this.currUser._id});
            pendingTaskPromise.then(function(resp) {
                this.AnalyticsService.trackSuccess(this, 'successfully got transactions for account(s)');
                this.isExpensesPage = this.NavigationService.isRouteActive(this.AppStates.root_core_navigation_expenses);
                if(this.isExpensesPage) {
                    this.refreshExpenses();
                    this.readingTransactions = false;
                }
                this.showToast();
            }.bind(this)).catch(function(error){
                this.AnalyticsService.trackError(this, 'failed to get transactions for account(s)', error);
                this.isExpensesPage = this.NavigationService.isRouteActive(this.AppStates.root_core_navigation_expenses);
                var msg = this.isExpensesPage ? 'Failed to import expenses. Please refresh page and retry' :
                    'Failed to import expenses. Please refresh expenses page and retry';
                var confirmBtnText = this.isExpensesPage ? 'OK' : 'Go to expenses';
                this.readingTransactions = false;
                this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.error, msg, confirmBtnText, 'close')
                    .then(function onConfirm() {
                        this.goToExpenses();
                    }.bind(this));
            }.bind(this));
        }

        goToExpenses() {
            if(!this.isExpensesPage) {
                this.AnalyticsService.trackClick(this, 'go to expenses');
                var route = this.user.isBookkeeperMode() ? this.AppStates.root_core_navigation_bookkeeping_expenses : this.AppStates.root_core_navigation_expenses;
                this.$state.go(route, {});
            }
        }

        refreshExpenses(isGetNextPage) {
            this.loadingExpenses = true;
            if (isGetNextPage) {
                this.pageNum++;
            } else {
                this.pageNum = 1;
            }

            var params = {
                sort_by: this.sortColumn,
                sort_desc: this.sortDirectionReverse || false,
                perPage: this.perPage,
                page: this.pageNum
            };
            if (this.curFilter && this.curFilter.value) {
                var today = this.moment();
                switch (this.curFilter.value) {
                    case '30_days':
                        params.to_date = today.format(this.apiDateFormat);
                        params.from_date = today.subtract(30,'day').format(this.apiDateFormat);
                        break;
                    case '90_days':
                        params.to_date = today.format(this.apiDateFormat);
                        params.from_date = today.subtract(90,'day').format(this.apiDateFormat);
                        break;
                    case 'custom':
                        if (this.filterStartDate) {
                            params.from_date = this.filterStartDate.format(this.apiDateFormat);
                        }
                        if (this.filterEndDate) {
                            params.to_date = this.filterEndDate.format(this.apiDateFormat);
                        }
                        break;
                }
            }


            return this.CompaniesManager.getExpenses(this.company, params).then(
                function success(resp) {
                    if (isGetNextPage) {
                        var expIds = {};
                        this.expenseItems.forEach(function(item) {
                            expIds[item._id] = true;
                        });
                        resp.data.forEach(function (expense) {
                            if (!expIds[expense._id]) {
                                this.expenseItems.push(expense);
                            }
                        }.bind(this));
                    } else {
                        this.expenseItems = resp.data;
                    }

                    if(this.showImportExpenses) {
                        this.populateExpenseSource();
                    }

                    this.hasMoreExpenses = resp.data.length === this.perPage;

                    this.showEmptyState = this.expenseItems.length === 0;
                    this.showRestrictedEmptyState = this.isFeatureRestricted && this.showEmptyState;

                    if (!this.isFeatureRestricted) {

                        // Make sure the user has subscription.
                        if (this.currUser.account && this.currUser.account.subscription) {
                            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.pricing_section_feature,
                                {feature: this.FeatureRestrictionService.features.expenses, current_plan: this.currUser.account.subscription.plan_code, is_restricted: this.isFeatureRestricted});
                        }
                    }

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_expense_change_filter,{
                        filter: this.curFilter.value,
                    });
                    this.loadingExpenses = false;
                }.bind(this),
                function error() {
                    this.loadingExpenses = false;
                }.bind(this));
        }

        hasImportedExpenses() {
            return this.showImportExpenses && this.expenseItems && this.expenseItems.length > 0;
        }

        populateExpenseSource() {
            this.getAccountsData()
                .then(function(resp) {
                        this.expenseItems.forEach(function(expense) {
                            if(!expense.source) {
                                if(this.accounts && this.accounts[expense.bank_account_datum_id]) {
                                    expense.source = this.accounts[expense.bank_account_datum_id].institution_name || '';
                                } else {
                                    expense.source = 'Manual';
                                    if(!this.showImportExpenses) {
                                        this.manualExpenseItems.push(expense);
                                    }
                                }
                            }
                        }.bind(this));
                }.bind(this))
                .catch(function(error) {
                   this.AnalyticsService.trackError(this, 'failed to get accounts data', error);
                }.bind(this));
        }

        filterUpdated() {
            this.loadingExpenses = true;
            if (this.curFilter.value === 'custom') {
                this.showDateRange = true;
            } else {
                this.showDateRange = false;
            }
            this.refreshExpenses();
        }

        setFilterStartDate(date) {
            this.loadingExpenses = true;
            var momentDate = this.moment(date, 'MMM DD, YYYY');
            if (momentDate.isValid()) {
                this.filterStartDate = momentDate;
                this.refreshExpenses();
            }
        }

        setFilterEndDate(date) {
            this.loadingExpenses = true;
            var momentDate = this.moment(date, 'MMM DD, YYYY');
            if (momentDate.isValid()) {
                this.filterEndDate = momentDate;
                this.refreshExpenses();
            }
        }

        downloadCsv($event) {
            this.csvLinkText = this.inProgressText;
            var params = {};
            if (this.curFilter && this.curFilter.value) {
                var today = this.moment();
                switch (this.curFilter.value) {
                    case '30_days':
                        params.to_date = today.format(this.apiDateFormat);
                        params.from_date = today.subtract(30, 'day').format(this.apiDateFormat);
                        break;
                    case '90_days':
                        params.to_date = today.format(this.apiDateFormat);
                        params.from_date = today.subtract(90, 'day').format(this.apiDateFormat);
                        break;
                    case 'custom':
                        if (this.filterStartDate) {
                            params.from_date = this.filterStartDate.format(this.apiDateFormat);
                        }
                        if (this.filterEndDate) {
                            params.to_date = this.filterEndDate.format(this.apiDateFormat);
                        }
                        break;
                }
            }
            this.CompaniesManager.downloadExpenseCsv(this.company, params)
                .then(function success(resp) {
                    this.CompaniesManager.downloadReportSecurely(resp.data.s3_uri)
                        .then(function () {
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_expense_export);
                        }.bind(this))
                        .finally(function () {
                            this.csvLinkText = this.downloadCsvText;
                        }.bind(this));
                }.bind(this))
                .catch(function error(resp) {
                    console.log(resp.data.error_message)
                    this.csvLinkText = this.downloadCsvText;
                }.bind(this));
        }

        getAccountsData() {
            return this.UsersManager.getPlaidBusinessSubAccounts(this.currUser)
                .then(function onSuccess(resp) {
                    if(resp && resp.data) {
                    this.AnalyticsService.trackSuccess(this, 'got sub accounts', {numOfAccounts: resp.data.length});
                        this.accounts = resp.data.reduce(function(accountsObj, account) {
                            accountsObj[account._id] = account;
                            return accountsObj;
                            } ,{});
                    }
                    else {
                        this.accounts = null;
                    }
                }.bind(this)).catch(function onError(error) {
                    this.AnalyticsService.trackError(this, 'failed to get sub accounts', error);
                    this.accounts = null;
                }.bind(this));
        }

        showToast() {
            this.ToastService.showSuccess({
                contentTranslation: 'Expenses were imported successfully',
                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                dismissOnTimeout: true,
                dismissButton: true,
                timeout:3000
            });
        }
    }

    Components.Expenses = {
        controller: ExpensesComponent,
        controllerAs: 'expensesVm',
        name: 'hbExpenses',
        templateUrl: 'angular/app/modules/core/features/expenses/expenses_component_template.html'
    };
}());
