(function () {
    'use strict';
    Controllers.NgReactRedirectWrapperController = class NgReactRedirectWrapperController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, StatsigService, integrationName) {
            super($scope, $injector);
            this.StatsigService = StatsigService;
            this.shouldRenderReact = undefined;
            this.integrationName = integrationName;

            this.StatsigService.isGateEnabled('ng2react-google-integration', false)
                .then(isEnabled => {
                    this.shouldRenderReact = isEnabled;
                })
                .catch(() => {
                    this.shouldRenderReact = false;
                });
        }
    };
}());
