(function () {
    "use strict";

    Directives.WorkspaceFeedDirective = function WorkspaceFeedDirective() {
        // @ngInject
        function WorkspaceFeedDirectiveControllerCtor($scope, $stateParams, $injector, $window, $translate, $timeout, $sce, _, JsSlicklightboxService,
                                                      InitialAppLoadParamsService, WorkspacesManager, UsersManager, FeedService,
                                                      AnalyticsService, Enums, CompanyService, ModalService, $filter, $,
                                                      EventsManager, WebsocketHelperService, AbTestService, PvlService, $location,
                                                      RepositoryService, OnboardingBotManager, UiPersistenceService, WorkspaceService,
                                                      UIUtils, MemberReferralsManager, ngToast, DeviceService,
                                                      PopupMessageService, FlowsBetaUserTypeService, AppConfigService, PendingTasksManager,
                                                      FeatureRestrictionService, UserService, StatsigService, ModelFactory) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'WorkspaceFeedDirectiveController';

            this.$scope = $scope;
            this.$ = $;
            this.DeviceService = DeviceService;
            this.PendingTasksManager = PendingTasksManager;
            this.$timeout = $timeout;
            this.InitialAppLoadParamsService = InitialAppLoadParamsService;
            this.AnalyticsService = AnalyticsService;
            this.WorkspacesManager = WorkspacesManager;
            this.MemberReferralsManager = MemberReferralsManager;
            this.ngToast = ngToast;
            this.CompanyService = CompanyService;
            this.AbTestService = AbTestService;
            this.Enums = Enums;
            this.FeedService = FeedService;
            this.WorkspaceService = WorkspaceService;
            this.PvlService = PvlService;
            this.UiPersistenceService = UiPersistenceService;
            this.EventsManager = EventsManager;
            this.WebsocketHelperService = WebsocketHelperService;
            this.$sce = $sce;
            this.$translate = $translate;
            this.$stateParams = $stateParams;
            this.$window = $window;
            this.$location = $location;
            this.OnboardingBotManager = OnboardingBotManager;
            this.PopupMessageService = PopupMessageService;
            this.UIUtils = UIUtils;
            this.ModalService = ModalService;
            this._ = _;
            this.$filter = $filter;
            this.JsSlicklightboxService = JsSlicklightboxService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.UserService = UserService;
            this.ModelFactory = ModelFactory;
            this.galleryInitialized = false;
            this.howItLooksImagesForFiles = [
                "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1524037769/empty_states/how-it-works/activity_image_01.png",
                "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1524046387/empty_states/how-it-works/activity_image_02.png",
                "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1523892464/empty_states/how-it-works/activity_image_03.png",
                "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1523892466/empty_states/how-it-works/activity_image_04.png"
            ];
            this.howItLooksImagesForFlows = [
                'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1642000697/empty_states/how-it-works/activity_image_01_for_flow.png',
                'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1642000702/empty_states/how-it-works/activity_image_02_for_flow.png',
                'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1642000699/empty_states/how-it-works/activity_image_03_for_flow.png',
                'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1642000700/empty_states/how-it-works/activity_image_04_for_flow.png'
            ];

            this.paginationSettings = {
                currPage: 1,
                pageSize: 50,
                isAllDataVisible: true
            };

            this.limit = this.paginationSettings.pageSize;
            this.workspaceId = $stateParams.workspace_id;
            this.eventId = $stateParams.event_id;
            this.toggleCreateFileOptions = {scroll: true, delay: 400};
            this.lightboxContainer = "#how-it-looks-feed";
            this.emptyStatePrimaryCtaAnalyticsData = {
                eventName: this.AnalyticsService.analytics_events.create_new,
                data: {
                    source: 'workspace_feed_empty_state',
                    page: 'workspace_feed',
                    unique: 'empty_state_primary_cta'
                }
            };
            this.emptyStateTextInlineCtaAnalyticsData = {
                eventName: this.AnalyticsService.analytics_events.action,
                data: {
                    page: 'workspace_feed',
                    unique: 'empty_state_secondary_cta' // event data stays the same even though secondary cta has been moved to inline cta for backwards compatibility
                }
            };

            AnalyticsService.trackPageView(this, 'workspace feed');

            RepositoryService.persistentPut('event:' + this.eventId, {
                tabToOpen: Enums.workspaceTabTypes.feed,
                latestWsId: this.workspaceId
            });

            this.cachedFeedItemsAmount = 0;

            this.focusedAfterFeedUpdate = true;
            this.ItemTypes = Enums.FeedItemTypes;

            this.currUser = UsersManager.getCurrUser();
            this.currUserId = this.currUser._id;
            this.isVendor = this.currUser.isVendor();
            this.feedUsers = {};

            this.isVendorAsClient = false;
            if (this.isVendor &&
                this.currUser.workspace_user_data &&
                this.currUser.workspace_user_data.workspace_role === 'host') {
                this.isVendorAsClient = true;
            }

            this.isViewOnlyMode = false;
            this.event = this.EventsManager.getEvent(this.eventId);

            this.workspace = WorkspacesManager.getWorkspace(this.workspaceId, false, false);
            this.isClient = this.currUser.isClient();
            this.showClientPortalEmailComposer = undefined;
            this.register(this.workspace, 'success', this.gotWorkspace);
            this.registerOnce(this.workspace, 'success', this.firstClientBotInit);

            FeedService.registerFeed(this.workspace.feed, null);
            this.gotItems = this.workspace.feed && this.workspace.feed.hasItems();
            this.isBooked = null;

            this._hasConflict = null;
            this.conflicts = null;

            this.lastRequestedPVL = null;
            this.showPvlClientRequest = false;
            this.pendingPvlRequestViewModel = {};
            this.pvlRequestDismissOptions = this.PvlService.getPvlRequestDismissOptions();

            this.didCheckForUrlAction = false;
            this.lastMessageSubject = null;

            this.firstInquiryPvl = false;

            this.createReferral = false;

            this.messageEditorConfig = {
                showSharePreferredVendors: this.isVendor,
                isAlternateVendorRecommendations: false
            };

            this.undoMessageFunc = function (taskId) {
                AnalyticsService.track(this, AnalyticsService.analytics_events.undo_workspace_feed_message, {undo: true});
                var cancelMessage = this.$translate.instant('WORKSPACE.UNDO._MESSAGE_');
                this.PendingTasksManager.cancelPendingTask(taskId, cancelMessage).then(function () {
                    this.WorkspacesManager.getFeedForWorkspace(this.workspace, this.workspaceId);
                }.bind(this)).catch(function () {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'Failed to undo message');
                }.bind(this))
            }.bind(this);

            // this func is passed and triggered by the feed message editor
            this.sendMessageFunc = function (workspace, messageBody, attachedFiles, pvlData, subject, feedToReplyId, replyMode, scheduledSendDate, delayInSeconds, onCptCreated, recipientIds) {
                const isDisabled = this.FeatureRestrictionService.checkIfBlockedAccess({
                    source: 'send_email',
                    actionType: 'click'
                });
                if (isDisabled) {
                    return;
                }

                // analytics
                var params = {
                    workspace_id: this.workspaceId,
                    reply_mode: replyMode,
                    scheduled_email: !!scheduledSendDate
                };
                if (this.firstInquiryPvl) {
                    params["first_inquiry_pvl"] = true;
                }
                AnalyticsService.track(this, AnalyticsService.analytics_events.send_workspace_feed_message, params);
                // pvl analytics - only vendors daa!!!
                if (this.isVendor && pvlData && pvlData.pvl_id) {
                    this.PvlService.sendPvlSentAnalytics(pvlData, {workspace_id: this.workspaceId}, this);
                }

                var doAfterSendSuccess = function (self) {
                    self.afterWorkspaceMessageSent(workspace, messageBody, attachedFiles, pvlData);
                    var shouldCollpaseEditorAfterSent = true;
                    if (self.createReferral) {
                        this.createMemberReferral();
                    }
                    self.lastMessageSubject = subject;
                    return shouldCollpaseEditorAfterSent;
                };
                var taskData = {
                    subject: subject,
                    html_body: messageBody,
                    general_files: attachedFiles.generalFiles,
                    image_files: attachedFiles.imageFiles,
                    feed_to_reply_id: feedToReplyId,
                    scheduled_send_date: scheduledSendDate,
                    force: false,
                    ws_id: workspace._id,
                    delay: delayInSeconds
                };

                if (this.currUser.isVendor() && recipientIds) {
                    taskData.recipient_ids = recipientIds;
                }

                if (pvlData.pvl_id) {
                    taskData.pvl_id = pvlData.pvl_id;
                }

                let cptCreatedCb = function (task_id) {
                    if (this.currUser.isVendor() && this.isUndoSendEnabled) {
                        this.WorkspacesManager.getFeedForWorkspace(workspace, this.workspaceId).then(function success(resp) {
                            onCptCreated(task_id);
                        }.bind(this));
                    }
                }.bind(this);

                var handleResults = function (res) {
                    if ((res && res.workspaces && res.workspaces.result.failed) || (res.data && res.data.error_data && res.data.error_data.failed)) {
                        var send_results = res.workspaces ? res.workspaces.result.send_results : res.data.error_data.send_results;
                        var succeeded = send_results.filter(function (item) {
                            return item.success;
                        });
                        var failed = send_results.filter(function (item) {
                            return !item.success;
                        });
                        if (failed.length > 0) {
                            return this.ModalService.openEmailSendErrorModal(this.workspace.members, failed, succeeded, !!scheduledSendDate)
                                .then(function success() {
                                    taskData.force = true;
                                    return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendWorkspaceMessage, taskData, false, cptCreatedCb)
                                        .then(function success() {
                                            return this.WorkspacesManager.getFeedForWorkspace(workspace, this.workspaceId).then(function success(resp) {
                                                return doAfterSendSuccess(this);
                                            }.bind(this));
                                        }.bind(this)).catch(function fail(res) {
                                            this.WorkspacesManager.getFeedForWorkspace(workspace, this.workspaceId).then(function success(resp) {
                                                if (!res.canceled) {
                                                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                                                }
                                            }.bind(this));
                                            return false;
                                        }.bind(this));
                                }.bind(this))
                                .catch(function error() {
                                    // modal closed or cancel button
                                    return false;
                                }.bind(this));
                        }
                    } else if (res.canceled) {
                        return false;
                    } else if ((res.data && res.data.error === true) || typeof res === 'string') {
                        this.WorkspacesManager.getFeedForWorkspace(workspace, this.workspaceId).then(function success() {
                            var msg = 'ERRORS.SERVER_API._UNKNOWN_';
                            if (typeof res === 'string') {
                                msg = res;
                            } else if (res.data.error_message) {
                                msg = res.data.error_message;
                            }
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, msg);
                        }.bind(this));
                    } else {
                        return this.WorkspacesManager.getFeedForWorkspace(workspace, this.workspaceId).then(function success(resp) {
                            return doAfterSendSuccess(this);
                        }.bind(this));
                    }
                }.bind(this);

                taskData.html_body = this.UIUtils.toBinaryBase64(messageBody);
                return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendWorkspaceMessage, taskData, false, cptCreatedCb)
                    .then(function success(res) {
                       return handleResults(res);
                    }.bind(this)).catch(function (res) {
                      //   When we return an error from the cpt validation there are some case we want to handle it the same way.
                      return handleResults(res);
                    }.bind(this));
            }.bind(this);

            // load feed for workspace
            this.loadFeedForTheFirstTime();

            this.initFeedUsers();
            this.initFlows();

            this.referralSuggestionsBlacklist = UiPersistenceService.getUiPersistence(UiPersistenceService.keys.referralSuggestionsBlacklist,
                {userEmails: []});

            // Check where the user came from
            if (!this.currUser.isActivated() && this.$stateParams.origin && this.$stateParams.origin === "email") {
                this.firstInquiryPvl = true;
                var ph = $translate.instant('COMMON_FEED_COMPONENTS.MESSAGE_EDITOR._PVL_INQUIRY_PLACEHOLDER_');
                this.setFeedEditorPlaceholder(ph);
            }

            // clean up
            $scope.$on('$destroy', function () {
                FeedService.unregisterFeed();
                this.unregister(this.workspace, 'success');
                WebsocketHelperService.unregisterFromRoom(this.workspaceFeedId);

                // clear search params if they existed so they won't stay in the url
                this.InitialAppLoadParamsService.removeParams(['action', 'source', 'action_data']);
            }.bind(this));

            var emptyStateTextKey = this.FlowsBetaUserTypeService.hasFlows ? 'EMPTY_STATES_SCREEN.ACTIVITY._TEXT_' : 'EMPTY_STATES_SCREEN.ACTIVITY._TEXT_FILES_';
            this.emptyStateText = $translate.instant(emptyStateTextKey);
            var activityLabel = this.isClient ? 'WORKSPACE.FEED._ACTIVITY_TITLE_CLIENT_' : 'WORKSPACE.FEED._ACTIVITY_TITLE_MEMBER_';
            this.activityTitle = $translate.instant(activityLabel);

            if (this.isVendor) {
                this.UserService.getShouldShowEmailDeliverabilityTest();
                StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.EmailViewer).then(function (result) {
                    this.isReactEmailViewerEnabled = result;
                }.bind(this));
                StatsigService.isGateEnabled('undo_send_workspace_message').then(function (result) {
                    this.isUndoSendEnabled = result;
                }.bind(this))
            } else {
                this.isReactEmailViewerEnabled = false;
                this.isUndoSendEnabled = false;
            }

            if (this.isClient) {
                StatsigService.isGateEnabled('ng2react_client_portal_email_composer').then(function (result) {
                    this.showClientPortalEmailComposer = result;
                }.bind(this));
            }
        }

        var WorkspaceFeedDirectiveController = Class(Controllers.BaseController, {

            constructor: WorkspaceFeedDirectiveControllerCtor,

            firstClientBotInit: function firstClientBotInit() {
                this.onboardingBotActive = false;
                this.lastOnboardingBotState = "";
                this.hasOnboardingBotAction = false;
                this.doingBotAction = false;
                this.reloadClientBotTooltip = false;
                this._animateClientBotTooltip();
            },

            initClientBot: function initClientBot() {
                this.onboardingBotActive = this.OnboardingBotManager.isOnboardingBotActiveOnWS(this.workspace);

                if (this.onboardingBotActive && this.workspace.onboarding_bot_state && this.lastOnboardingBotState !== this.workspace.onboarding_bot_state) {
                    this.lastOnboardingBotState = this.workspace.onboarding_bot_state;
                    this.onboardingBotAction = this.OnboardingBotManager.getActionData(this.workspace.onboarding_bot_actions ? this.workspace.onboarding_bot_actions[0] : "");
                    this.hasOnboardingBotAction = this.OnboardingBotManager.hasOnboardingBotAction(this.workspace);
                    this.shouldSeeClientBotTooltip = true;
                }
            },

            openClientBotRecapModal: function openClientBotRecapModal() {
                this.ModalService.openClientBotRecapModal();
            },

            trackBotAnalytics: function trackBotAnalytics(source) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.onboarding_bot_cta, {
                    bot_action: this.onboardingBotAction.key,
                    selected_option: source
                });
            },

            onboardingBotCTA: function onboardingBotCTA() {
                this.trackBotAnalytics('next');
                this.onboardingBotDoAction();
            },

            openVideoModalCTA: function openVideoModalCTA(modalContentType) {
                this.trackBotAnalytics('learn_more');
                this.ModalService.openVideoCarouselModal(modalContentType);
            },

            updateIsAllDataVisible: function updateIsAllDataVisible() {
                this.$timeout(function () {
                    this.paginationSettings.isAllDataVisible = (this.sortedFeedItems.length <= this.limit);
                }.bind(this), 110);
            },

            addMoreFeedItems: function addMoreFeedItems(event) {
                this.previousScroll = this.$(this.$window).scrollTop();
                this.loadingMoreFeedItems = true;

                this.paginationSettings.currPage++;
                this.limit = this.paginationSettings.currPage * this.paginationSettings.pageSize;
                this.updateIsAllDataVisible();


                this.$timeout(function () {
                    this.$(this.$window).scrollTop(this.previousScroll);
                    this.loadingMoreFeedItems = false;
                }.bind(this), 150);
            },

            onboardingBotDoAction: function () {
                if (this.workspace.workspace_pipeline_data) {
                    var currStage = this.workspace.workspace_pipeline_data.current_stage.stage_id;
                }
                this.doingBotAction = true;
                this.doingBotActionDone = false;

                if ('whats_next' === this.workspace.onboarding_bot_actions[0]) {
                    this.$timeout(function () {
                        this.doingBotAction = false;
                        this._animateClientBotTooltip();
                        this.shouldSeeClientBotTooltip = false;
                        this.openClientBotRecapModal();
                    }.bind(this), 500);
                } else {
                    this.OnboardingBotManager.doAction(this.workspace, this.onboardingBotAction.key).then(function success() {
                        if (currStage && this.workspace.workspace_pipeline_data &&
                            this.workspace.workspace_pipeline_data.current_stage.stage_id !== currStage) {
                            this.WorkspacesManager.onWorkspaceStageUpdated(this.workspace);
                        }
                    }.bind(this)).catch(function error() {
                        //
                    }.bind(this)).finally(function botActionFinally() {
                        this.doingBotAction = false;
                        this._animateClientBotTooltip();
                    }.bind(this));
                }
            },

            _animateClientBotTooltip: function _animateClientBotTooltip() {
                this.reloadClientBotTooltip = true;
                this.$timeout(function () {
                    this.reloadClientBotTooltip = false;
                }.bind(this), 1000);
            },

            showScheduleCanceledToast: function showScheduleCanceledToast(flowTitle) {
                var toastContent = '<div class="flow-action-toast-content">\
                                    <i class="icon icon-hb-nx-trash"></i>\
                                    <div class="text-container">\
                                        <div>Scheduled send of "' + this.UIUtils.sanitize(flowTitle) + '" smart file was canceled</div>\
                                    </div>\
                                </div>';
                this.ngToast.create({
                    content: this.$sce.trustAsHtml(toastContent),
                    className: 'flow-action-toast alert-danger',
                    dismissOnTimeout: true,
                    timeout: 4000,
                    dismissButton: false,
                    compileContent: this.$scope
                });
            },

            updateAfterDelete: function updateAfterDelete(connectedObjId) {
                var allFeedItems = this.workspace.feed.feed_items;
                var itemIndex = allFeedItems.findIndex(function (fi) {
                    return (fi.data._id || fi._id) === connectedObjId;
                });
                if (itemIndex !== -1 && connectedObjId) {
                    allFeedItems.splice(itemIndex, 1);
                }

                if (this._.isArray(allFeedItems)) {
                    this.sortedFeedItems = this.$filter('orderBy')(allFeedItems, 'origin_date', true);
                    this.updateIsAllDataVisible();
                }

                var flow = this.flowsToDisplay.find(function (flow) {
                    return flow._id === connectedObjId;
                });
                if (flow) {
                    flow.scheduled_to_be_sent = null;
                    this.showScheduleCanceledToast(flow.title);
                }
                this.initFlows();
            },

            gotWorkspace: function gotWorkspace() {
                var allFeedItems = this.workspace.feed.feed_items;
                if (allFeedItems && allFeedItems.length) {
                    this.sortedFeedItems = this.$filter('orderBy')(allFeedItems, 'origin_date', true);
                    this.updateIsAllDataVisible();
                }


                this.workspaceFeedId = this.workspace.feed._id ? this.workspace.feed._id : null;
                this.isViewOnlyMode = this.workspace.isViewOnlyMode();
                this.isBooked = this.workspace.isBooked();

                // update pvl pending request
                this.updatePendingPvlRequestForWorkspace();
                this.calculateIfToShowPvlClientRequest();

                if (!this.didCheckForUrlAction) {
                    this.checkUrlAction();
                }

                this.initFeedUsers();
                this.initClientBot();
                this.initFlows();
                this.getLastMessageForReply();
            },

            initFlows: function initFlows() {
                this.flowsToDisplay = this.filterFlows();
                this.creator = this.workspace.members.find(function (member) {
                    return member._id === this.workspace.creator_id;
                }.bind(this));
                this.showFlows = this.flowsToDisplay && this.flowsToDisplay.length;
            },

            isCurrentUserCreator: function isCurrentUserCreator() {
                return this.creator && this.creator._id === this.currUser._id;
            },

            initFeedUsers: function initFeedUsers() {
                this.feedUsers = {};
                angular.forEach(this.workspace.members, function (member) {
                    this.feedUsers[member._id] = member;
                }.bind(this));
                this.deletedUsers = {};
                angular.forEach(this.workspace.deleted_members, function (member) {
                    this.deletedUsers[member._id] = member;
                }.bind(this));
            },

            filterFlows: function filterFlows() {
                var flowStatusesToHide = ['draft', 'completed'];

                return this.workspace.flows && this.workspace.flows.filter(function (flow) {
                    return (flow.scheduled_to_be_sent || !flowStatusesToHide.includes(flow.status)) && !flow.expired;
                }.bind(this));
            },

            getFeedForWorkspace: function getFeedForWorkspace() {
                return this.WorkspacesManager.getFeedForWorkspace(this.workspace, this.workspaceId)
                    .then(this.markCurrentFeedItemsAsSeen.bind(this));
            },

            markCurrentFeedItemsAsSeen: function markCurrentFeedItemsAsSeen() {

                if (this.currUser.isAdminLogin()) {
                    return;
                }

                var feedItems = this.workspace.feed.feed_items;
                var itemsToMark = feedItems.filter(function (item) {

                    var usersExistsInFeedTracking = this._.findIndex(item.feed_tracking, function (ft) {
                        return ft.to_user_id === this.currUserId && ft.source === 'feed';
                    }.bind(this)) !== -1;

                    return item.sender_id && item.sender_id !== this.currUserId && !usersExistsInFeedTracking;
                }.bind(this));
                if (itemsToMark.length) {
                    return this.WorkspacesManager.markFeedItemsAsSeen(this.workspace._id, itemsToMark);
                }
            },

            calculateIfToShowPvlClientRequest: function calculateIfToShowPvlClientRequest() {
                var disable_client_pvl_requests = this.workspace.disable_client_pvl_requests || false;

                this.showPvlClientRequest =
                    !this.PvlService.hasAnyPvlOnWorkspace(this.workspace) &&
                    !this.isVendor &&
                    this.isBooked &&
                    !this.workspace.dismissed_feed_pvl_request &&
                    !disable_client_pvl_requests &&
                    !this.event.creator.isConsultantTrialStartedAfterABTestDate();
            },

            loadFeedForTheFirstTime: function () {
                this.getFeedForWorkspace().then(
                    function success() {

                        // mark as loaded
                        this.gotItems = true;

                        // update cached items count
                        var feedItems = this.workspace.feed.feed_items;
                        this.cachedFeedItemsAmount = !!feedItems ? feedItems.length : 0;

                        // register for feed updates trigger
                        this.WebsocketHelperService.registerToRoom(this.workspaceFeedId, this.refreshFeed.bind(this));
                    }.bind(this)
                );
            },

            refreshFeed: function refreshFeed() {
                this.getFeedForWorkspace().then(
                    function success() {
                        var newestSentBySelf, feedAmountChanged;
                        var feedItems = this.workspace.feed.feed_items;

                        // updated feed items length
                        var itemsLength = !!feedItems ? feedItems.length : 0;

                        // check for updates
                        if (itemsLength > 0) {
                            newestSentBySelf = feedItems[itemsLength - 1].sender_id === this.currUserId;
                            feedAmountChanged = this.cachedFeedItemsAmount !== itemsLength;
                        }

                        if (feedAmountChanged && !newestSentBySelf) {
                            if (!document.hasFocus()) {
                                // todo: ios is onpageshow not onblur?

                                // mark
                                this.focusedAfterFeedUpdate = false;

                                // mark where to show the new feed message separator
                                this.oldestNewMessageIndex = itemsLength - this.cachedFeedItemsAmount - 1;

                                // update cached items count
                                this.cachedFeedItemsAmount = itemsLength;

                                // register once on focus to show the new feed items
                                this.$window.onfocus = function () {
                                    this.focusedAfterFeedUpdate = true;
                                    this.$window.onfocus = null;
                                }.bind(this);
                            }

                        }
                        // Send last feed message subject on feed update
                        this.getLastMessageForReply();
                        this.$scope.$broadcast('feedRefreshed');
                    }.bind(this)
                );
            },

            showConflictNotification: function showConflictNotification() {
                return (this.hasConflict() && this.canShow());
            },

            canShow: function canShow() {
                return (!this.event.hide_conflict_pvl_action);
            },

            hasConflict: function hasConflict() {
                if (typeof (this._hasConflict) === "boolean") {
                    return this._hasConflict;
                }
                if (this.event._id && this.event.event_date) {
                    this._hasConflict = false; //temp answer till server returns answer
                    this.EventsManager.getConflictingEvents(this.event._id).then(
                        function success(resp) {
                            if (resp.data.has_conflict) {
                                this._hasConflict = true;
                                this.conflicts = resp.data;
                            } else {
                                this._hasConflict = false;
                            }
                            this.messageEditorConfig.isAlternateVendorRecommendations = (this._hasConflict && this.canShow());
                        }.bind(this),
                        function error() {
                        }
                    );
                } else {
                    return false;
                }
            },

            respondToClientPvlRequest: function respondToClientPvlRequest() {
                // open pvl edit
                this.openEditPreferredVendorsList('request_in_workspace_feed');
            },

            updatePendingPvlRequestForWorkspace: function updatePendingPvlRequestForWorkspace() {

                this.lastRequestedPVL = this.getPendingPvlRequestForWorkspace();

                this.pendingPvlRequestViewModel = {
                    hasPendingPvlRequest: !!this.lastRequestedPVL,
                    pendingPvlRequestClientName: this.PvlService.getPvlRequesterName(this.workspace, this.lastRequestedPVL),
                    pendingPvlRequestVendorTypes: this.PvlService.getRequestedVendorTypesStringFromPvl(this.lastRequestedPVL)
                };

                this.hasAnyPvlRequest = this.PvlService.hasAnyPvlOnWorkspace(this.workspace);
            },

            cleanPendingPvlRequestForWorkspace: function cleanPendingPvlRequestForWorkspace() {

                this.lastRequestedPVL = null;

                this.pendingPvlRequestViewModel = {
                    hasPendingPvlRequest: false,
                    pendingPvlRequestClientName: null,
                    pendingPvlRequestVendorTypes: ""
                };

            },

            getPendingPvlRequestForWorkspace: function getPendingPvlRequestForWorkspace() {
                return this.PvlService.getPendingPvlRequestForWorkspace(this.workspace, this.currUserId);
            },

            onDismissPvlRequest: function onDismissPvlRequest(option) {
                this.WorkspacesManager.dismissRequestsForPvl(this.workspace, this.lastRequestedPVL._id, option);
            },

            openEditPreferredVendorsList: function openEditPreferredVendorsList(source) {

                // Analytics
                this.AnalyticsService.track(this, "create preferred vendors list", {source: source});

                // pass pvl request id
                //var pvl_id = this.lastRequestedPVL ? this.lastRequestedPVL._id : undefined;

                // call the create async style
                if (!this.FeedService.createPreferredVendorList(this.lastRequestedPVL)) {
                    // feed editor not yet registered. call again after registration.
                    this.register(this.workspace.feed, "registerFeedEditor", function () {
                        this.FeedService.createPreferredVendorList(this.lastRequestedPVL);
                    }.bind(this));
                }
            },

            afterWorkspaceMessageSent: function afterWorkspaceMessageSent(workspace, messageBody, attachedFiles, pvlData) {

                // if PVL was sent hide pvl conflict action
                if (pvlData.pvl_id) {
                    this.EventsManager.setPvlConflictView(this.event, true);
                    this.cleanPendingPvlRequestForWorkspace();
                }
            },

            checkUrlAction: function checkUrlAction() {

                if (this.didCheckForUrlAction) {
                    return;
                }

                var action = this.$stateParams.action;
                if (!action) {
                    this.didCheckForUrlAction = true;
                    return;
                }

                if (action === "open_pvl" && this.$stateParams.action_data) {
                    this.didCheckForUrlAction = true;
                    this.PvlService.showPreferredVendorList(this.$stateParams.action_data, this.eventId);
                } else if (action === "create_pvl" && this.lastRequestedPVL) {
                    this.didCheckForUrlAction = true;
                    this.openEditPreferredVendorsList(this.$stateParams.source || 'url_params');
                }
            },

            // lightbox test
            onLightboxShown: function onLightboxShown(event) {
                if (event.container === this.lightboxContainer) {
                    this.AnalyticsService.track(this, 'empty_state--how_it_looks', {
                        page: 'workspace_feed',
                        action: 'opened'
                    });
                }
            },

            onLightboxHidden: function onLightboxHidden(event) {
                if (event.container === this.lightboxContainer) {
                    this.AnalyticsService.track(this, 'empty_state--how_it_looks', {
                        stage: 'workspace_feed',
                        action: 'closed'
                    });
                }
            },

            onLightboxImageChanged: function onLightboxImageChanged(event) {
                if (event.container === this.lightboxContainer) {
                    this.AnalyticsService.track(this, 'empty_state--how_it_looks', {
                        stage: 'workspace_feed',
                        action: 'image_changed'
                    });
                }

            },

            seeHowItLooks: function seeHowItLooks() {
                var howItLooksImages = this.FlowsBetaUserTypeService.hasFlows ? this.howItLooksImagesForFlows : this.howItLooksImagesForFiles
                if (!this.galleryInitialized) {
                    this.JsSlicklightboxService.init(howItLooksImages, this.lightboxContainer);
                    this.JsSlicklightboxService.on(this.JsSlicklightboxService.LIGHTBOX_HIDDEN, this.onLightboxHidden.bind(this));
                    this.JsSlicklightboxService.on(this.JsSlicklightboxService.LIGHTBOX_SHOWN, this.onLightboxShown.bind(this));
                    this.JsSlicklightboxService.on(this.JsSlicklightboxService.LIGHTBOX_IMAGE_CHANGE, this.onLightboxImageChanged.bind(this));
                    this.galleryInitialized = true;
                }
                this.JsSlicklightboxService.show();
            },

            setFeedEditorPlaceholder: function setFeedEditorPlaceholder(text) {

                if (!this.FeedService.setFeedEditorPlaceholder(text)) {
                    // feed editor not yet registered. call again after registration.
                    this.register(this.workspace.feed, "registerFeedEditor", function () {
                        this.FeedService.setFeedEditorPlaceholder(text);
                    }.bind(this));
                }
            },

            showBulkBanner: function showBulkBanner(item) {
                return item.data.is_bulk_email && item.sender_id === this.currUserId;
            },

            showItem: function showItem(item) {
                var isSystemGenerated = item.isSystemGenerated();
                var isPaymentReminder = item.isEmailType(this.Enums.EmailTypes.payment_reminder);
                return !isSystemGenerated || (isPaymentReminder && item.isFlow()) || this.workspace.feed.show_system_generated_items;
            },

            isOwnerMode: function isOwnerMode() {
                return this.currUser.company && this.currUser.company._id === this.workspace.creator.company_id;
            },

            getLastMessageForReply: function getLastMessageForReply() {
                var allowedTypes = [this.ItemTypes.workspace_file_email, this.ItemTypes.workspace_message, this.ItemTypes.feed_message, this.ItemTypes.workspace_email, this.ItemTypes.workspace_flow_email];
                var unallowedEmailTypeCds = [this.Enums.EmailTypesCd.widget];
                var feedItems = this.workspace.feed.feed_items;
                var itemsToMark = feedItems.filter(function (item) {
                    return allowedTypes.indexOf(item.type_cd) !== -1 &&
                        unallowedEmailTypeCds.indexOf(item.data.email_type_cd) === -1 &&
                        !item.scheduled_for;
                }.bind(this));

                if (itemsToMark.length) {
                    itemsToMark = this._.sortBy(itemsToMark, function (item) {
                        return item.origin_date ? item.origin_date : item.created_at;
                    });

                    this.lastMessageToReply = itemsToMark[itemsToMark.length - 1];
                }
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/workspace/feed/workspace_feed_template.html',
            controller: WorkspaceFeedDirectiveController,
            controllerAs: 'workspaceFeedVm',
            bindToController: true
        };
    };

    // @ngInject


}());
