(function () {
    "use strict";

    // @ngInject
    function EventControllerCtor($scope, $state, $stateParams, $injector, AppStates, $document, $timeout, $translate, $window, $, ngToast, $sce,
                                 EventsManager, UsersManager, CompaniesManager, CompanyService, ImagesManager, NavigationService, OnboardingService,
                                 EventService, AnalyticsService, WorkspaceService, WorkspacesManager, DeviceService, ModalService, _, UiPersistenceService,
                                 PretrialersService, FeatureRestrictionService, StatsigService, Enums, DatadogRUMService) {

        var self = this;

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EventController';
        this.$window = $window;
        this.$scope = $scope;
        this.EventsManager = EventsManager;
        this.CompaniesManager = CompaniesManager;
        this.EventService = EventService;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this.ImagesManager = ImagesManager;
        this.$state = $state;
        this.$timeout = $timeout;
        this.NavigationService = NavigationService;
        this.ModalService = ModalService;
        this.CompanyService = CompanyService;
        this.$translate = $translate;
        this.WorkspaceService = WorkspaceService;
        this._ = _;
        this.$ = $;
        this.DeviceService = DeviceService;
        this.UiPersistenceService = UiPersistenceService;
        this.ngToast = ngToast;
        this.$sce = $sce;
        this.PretrialersService = PretrialersService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.StatsigService = StatsigService;
        this.Enums = Enums;
        this.DatadogRUMService = DatadogRUMService;

        this.leadSourcesLimit = 18;

        this.eventId = $stateParams.event_id;

        this.showPageOverlay = false;
        this.isCreateFileActive = false;

        this.event = EventsManager.getEventFromCache(this.eventId);
        if (this.event) {
            this.eventLoaded = true;
            this.handleUrlParams();
        } else {
            this.eventLoaded = false;
            this.event = EventsManager.getEvent(this.eventId, false, false); //get event from cache. if not exists create it in cache
        }

        this.OnboardingService = OnboardingService;

        this.eventName = this.event.event_name;
        this.currUser = UsersManager.getCurrUser();
        this.isMobile = DeviceService.checkIfMobileSize();
        this.isIpadScreenSizeOrSmaller = DeviceService.isIpadScreenSizeOrSmaller();
        this.isTouch = DeviceService.checkIfTouchScreen();
        this.isTablet = this.isTouch && this.isIpadScreenSizeOrSmaller; // heuristic

        if (this.currUser.isVendor() && !this.isInViewMode() && angular.isUndefined(this.company)) {
            this.company = this.CompaniesManager.getCurrCompany();
        }

        this.getWorkspaceCreatorName = function getWorkspaceCreatorName () {
            var workspaceId = this.$state.params.workspace_id;
            if(workspaceId && this.event.workspaces && this.event.workspaces.length > 0) {
                var currentWorkspace = this.event.workspaces.find(function (workspace) {
                    return workspace._id === workspaceId;
                });

                return currentWorkspace.creator.full_name
            }
        }

        this.reloadCarouselFlag = false;
        this.isCreatingNewLeadSource = false;
        self.selectedEventImage = {};
        angular.extend(self.selectedEventImage, self.event.event_image);

        this.WorkspaceService = WorkspaceService;

        this.editingWorkspaceNameTab=[];
        this.lastSavedWorkspaceName = [];

        this.register(this.event, 'success', this.gotEvent);
        this.eventTypes = this.event.creator.company.project_types || [{label: ''}];
        this.selectedEventType = EventService.getProjectTypeByString(this.event.creator.company, this.event.event_type);

        this.eventDetailsVisible = false;

        this.mobileCloseBtnSref = this.currUser.isClient() ? this.AppStates.root_core_navigation_files : this.AppStates.root_core_navigation_pipeline;

        EventsManager.getEvent(this.eventId, true, true).then(this.gotEvent.bind(this)).catch(function(error) {
            // currently the only way to set the last_event_visited in the client is by visiting another event.
            // this fix only sets the value in the client next user data fetch it will be set beck
            if (this.currUser.last_event_visited === this.eventId && error.status === 404) {
                this.currUser.last_event_visited = null;
                this.goToState(this.AppStates.root_core_navigation_emptyEvent, null, { location: false });
            }
        }.bind(this));

        this.selectInput = function() {
            var selectedInput = $timeout(function(){
                angular.element('input#tab-event').select();
            });
        };

        var imageMinHeight = 91;

        // It will load only after the statsig flag is init.
        var imageHeadSelector = angular.element('.ep-header-content-js');

        this.scrollToMinimizeImage = function scrollToMinimizeImage() {
            var originalHeight = parseInt(imageHeadSelector.css("max-height"));
            if (originalHeight) {
                $timeout(function() {
                    imageHeadSelector.scrollParent().scrollTo(0, originalHeight - imageMinHeight, 300);
                }, 50);
            }
        };

        if (this.currUser.isVendor()) {
            this.scrollToMinimizeImage();
        }

        this.FeatureRestrictionService.checkIfBlockedAccess({
            source: 'project',
            actionType: 'load'
        });

        this.scrollToHeightTransform = function scrollToHeightTransform(scrollPosition) {
            var originalHeight = parseInt(imageHeadSelector.css("max-height"));
            this.isMinimized = false;

            if(originalHeight) {
                if (scrollPosition === 0) {
                    imageHeadSelector.removeClass('animate-ep-header-1');
                } else if (scrollPosition > 25) {

                    if (scrollPosition >= (originalHeight - 210)) { // scrollPosition >= 110
                        imageHeadSelector.addClass('animate-ep-header-1');
                        this.isMinimized = true;
                    } else {
                        imageHeadSelector.removeClass('animate-ep-header-1');
                    }
                }
            }

            var heightToSet;

            if (scrollPosition === 0) {
                heightToSet = -1;
            } else if (scrollPosition >= (originalHeight - imageMinHeight)) {
                heightToSet = imageMinHeight; // 76
            } else {
                heightToSet = originalHeight - 100 * scrollPosition / 100;
            }

            if(heightToSet === imageMinHeight) {
                imageHeadSelector.addClass('animate-ep-header-static');
            } else {
                imageHeadSelector.removeClass('animate-ep-header-static');
            }

            return heightToSet;
        };

        var headerAnimation = function() {
            var topHeight = angular.element($window).scrollTop();
            var heightToSet = self.scrollToHeightTransform(topHeight);

            if(heightToSet < 0) {
                heightToSet = "";
            }

            // set height to header container
            angular.element('.ep-header').css({height: heightToSet});

        };

        angular.element($window).on('scroll', headerAnimation);

        this.EventService.eventLoaded(this.eventId);

        if (this.currUser.isVendor()) {
            this.workspaceVersion = 'workspace';
        } else {
            this.workspaceVersion = this.currUser.isClientPortalRevampEnabled() ? 'client_portal_revamp' : 'workspace';
        }

        //// LEAD SOURCE


        this.showLeadSource = false;
        this.showLeadSourceOpenText = false;
        //this.shouldSaveLeadSource = false;

        if ($stateParams.openEventsListOnLoad && !this.isMobile) {
            $timeout(function() {
                this.toggleLeftEventPanelOpen(true);
            }.bind(this), 1000);
        }

        $scope.$on('$destroy', function onDestroy(){
            self.EventService.eventUnloaded(self.eventId);
            $timeout.cancel(self.selectInput);
            angular.element($window).off('scroll', headerAnimation);
        });

        var updateProject = function(params) {
            Object.assign(self.event, params.detail);
        };
        var updateProjectEventName = 'project_updated';
        window.addEventListener(updateProjectEventName, updateProject);
        $scope.$on('$destroy', function() {
            window.removeEventListener(updateProjectEventName, updateProject);
        });
    }

    Controllers.EventController = Class(Controllers.BaseController, {

        constructor: EventControllerCtor,

        // This is used in angular feed and files tabs, specifically in their empty states
        // Once both places implement the empty state in react, this function can be removed
        toggleCreateFile: function toggleCreateFile(options) {
            const isDisabled = this.FeatureRestrictionService.checkIfBlockedAccess({
                source: 'create_new',
                actionType: 'click'
            });
            if (isDisabled) {
                return;
            }

            var _this = this;

            var scrollTopTo = 0;
            if (!_this.isMobile) {
                var uiViewOffset = _this.$("#eventUIView").offset().top;
                var projectHeaderHeight = _this.$(".ep-header-content-js").height();
                var firstLevelNavigation = _this.$(".first-level-navigation--js").height();
                var onboardingProgressBar = _this.$('.onboarding-progress-bar--js').height();
                var workspaceHeaderPaddingTop  = parseInt(_this.$('.nx-workspace__header--js').css('padding-top').replace('px', ''));

                scrollTopTo = uiViewOffset - (projectHeaderHeight + firstLevelNavigation + onboardingProgressBar + workspaceHeaderPaddingTop);
            }

            var delayAfterScroll = 50;

            var _toggleCreateFile = function() {
                _this.isCreateFileActive = !_this.isCreateFileActive;
                if (_this.isCreateFileActive) {
                    _this.StatsigService.getExperimentConfigValue(_this.Enums.StatsigExperiments.createNewFileButtonWorkspace,  'variant')
                        .then(function (variant) {
                            _this.AnalyticsService.trackClick(_this, _this.AnalyticsService.analytics_events.open_create_file, { is_mobile: _this.isMobile, copy_ab_variant: variant });
                        }.bind(_this));
                }
            };

            if (options && options.scroll) {
                var duration = 0;
                if (options.delay) {
                    duration = options.delay;
                }

                _this.$("html, body").animate({scrollTop: scrollTopTo }, duration)
            }

            if (options && options.delay && options.delay > -1) {
                _this.$timeout(_toggleCreateFile, options.delay + delayAfterScroll);
            } else {
                _toggleCreateFile();
            }

            if (options && options.showPageOverlay) {
                _this.showPageOverlay = true;
            } else {
                _this.showPageOverlay = false;
            }
        },

        hidePageOverlay: function hidePageOverlay() {
            this.showPageOverlay = false;
        },

        gotEvent: function gotEvent() {
            this.eventName = this.event.event_name;
            this.eventTypes = this.event.creator.company.project_types || [];
            this.selectedEventType = this.EventService.getProjectTypeByString(this.event.creator.company, this.event.event_type);
            this.isViewOnlyMode = this.event.isViewOnlyMode;

            this.leadSources = this.event.creator.company.sortedLeadSources();

            var workspaceId = this.$state.params.workspace_id;
            if(workspaceId && this.event.workspaces && this.event.workspaces.length > 0) {
                var currentWorkspace = this.event.workspaces.find(function (workspace) {
                    return workspace._id === workspaceId;
                });

                if (!currentWorkspace) {
                    this.DatadogRUMService.addError(new Error("Mismatch workspace and event"), {
                        message: "Mismatch workspace and event",
                        eventId: this.event._id,
                        workspaceId: workspaceId
                    });
                    this.$state.go(this.AppStates.root_core_navigation_home);
                }
            }

            //TODO remvoe this after migration
            if (this.leadSources.length === 0) {
                var leadSourceEnumList = angular.copy(this.company.isNonEventType() ? this.EventService.nonEventLeadSourceEnum : this.EventService.eventLeadSourceEnum);
                this.leadSources = this.EventService.getTranslatedLeadSourcesEnum(leadSourceEnumList);
            }
            this.selectedLeadSource = this.EventService.getSelectedEventLeadSource(this.event, this.leadSources);
            // Show lead source stars is he hadn't changed a lead source
            if (this.selectedLeadSource.label === 'Unknown' && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadSourceProTip, {show: true}).show) {
                this.shouldShowLeadSourceStars = true;
            }

            this.showLeadSource = !!this.event.lead_source;
            this.calcLeadSourceOpenTextPlaceholder();

            if (!this.loadExtraEventData) {
                this.currUser.last_event_visited = this.event._id;

                if (!angular.isDefined(this.selectedEventImage.url)) {
                    angular.extend(this.selectedEventImage, this.event.event_image);
                }
                this.loadExtraEventData = true;
            }

            if (!this.eventLoaded) {
                this.handleUrlParams();
                this.eventLoaded = true;
            }

        },

        // This is used here related to the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        sortedList: function sortedList(list, item) {
            if (item) {
                list.push(item);
            }

            return list.sort(function(a, b) {
                return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : b.label.toLowerCase() < a.label.toLowerCase() ? 1 : 0;
            });
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        addLeadSource: function addLeadSource(leadSourceText) {
            var tag = {
                label: leadSourceText,
                editable: true,
                open_text: false
            };

            this.leadSources = this.sortedList(this.leadSources, tag);

            return this.CompaniesManager.updateLeadSources(this.company, this.leadSources)
                .then(function updateLeadSourcesSuccess(list) {
                    return list.data.lead_sources.filter(function(i) {
                        return i.label === tag.label;
                    });
                });
        },

        // This is used here and in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        saveChanges: function saveChanges(changedProperty) {
            if (changedProperty === 'event lead source open text') {
                this.showLeadSourceAdd();
            }
            this.EventsManager.updateEventDetails(this.event);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.event_page_details_edit, {edited_property: changedProperty});
        },

        analyticsProperties: function analyticsProperties(){
            return this.EventService.generateAnalyticsProps(this.event);
        },

        addWorkspaceToEvent: function addWorkspaceToEvent(workspaceType, source) {
            this.creatingWorkspace = true;
            var analyticsArgs = this.analyticsProperties();

            this.EventService.addWorkspaceToEventCommand(
                this.event._id,
                workspaceType,
                source,
                analyticsArgs,
                this)
                .finally(function onFinally() {
                    this.creatingWorkspace = false;
                }.bind(this));
        },

        // This is used in workspace_template.html in the related projects widget
        // Once the widget is migrated to the new workspace, this function can be removed
        toggleEditWorkspace: function toggleEditWorkspace(index, workspace) {
            this.editingWorkspaceNameTab[index] = !this.editingWorkspaceNameTab[index];

            //this.selectInput();

            if (this.editingWorkspaceNameTab[index]) {
                this.lastSavedWorkspaceName[index] = workspace.workspace_tab_info.name;
            }
        },

        // This is used in workspace_template.html in the related projects widget
        // Once the widget is migrated to the new workspace, this function can be removed
        hideEditWorkspace: function hideEditWorkspace(index) {
            this.editingWorkspaceNameTab[index] = false;
        },

        // This is used in workspace_template.html in the related projects widget
        // Once the widget is migrated to the new workspace, this function can be removed
        updateWorkspaceName: function updateWorkspaceName(workspace, index) {
            var self = this;

            if (workspace.workspace_tab_info.name === this.lastSavedWorkspaceName[index] ||
                workspace.workspace_tab_info.name.length === 0) {
                workspace.workspace_tab_info.name = this.lastSavedWorkspaceName[index];
                return;
            }

            var analyticsArgs = this.WorkspaceService.generateAnalyticsProps(workspace);
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_updated_workspace_name, analyticsArgs);

            workspace.updateWorkspaceName().then(
                function success() {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_name_updated_successfully, analyticsArgs);
                },
                function error() {
                    workspace.workspace_tab_info.name = self.lastSavedWorkspaceName[index];
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_name_updated_error, analyticsArgs);
                }
            );
        },

        isInViewMode: function isInViewMode() {
            return this.event.isViewOnlyMode();
        },

        handleUrlParams: function handleUrlParams() {
            if (this.$state.params.action) {
                if (this.$state.params.action === "create_workspace" && this.$state.params.workspace_type) {
                    switch (this.$state.params.workspace_type) {
                        case "team":
                        case "design":
                        case "all_vendors":
                        case "timeline":
                            var analyticsArgs = this.analyticsProperties();
                            analyticsArgs.event_id = this.eventId;
                            analyticsArgs.workspace_type = this.$state.params.workspace_type;
                            analyticsArgs.source = this.$state.params.source;

                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_workspace_from_url, analyticsArgs);
                            this.addWorkspaceToEvent(this.$state.params.workspace_type, this.$state.params.source);
                    }
                }
            }
        },

        toggleLeftEventPanelOpen: function toggleLeftEventPanelOpen(newState) {
            if (typeof(newState) === "boolean") {
                this.appState.leftPanelEventOpen = newState;
            } else {
                this.appState.leftPanelEventOpen = !this.appState.leftPanelEventOpen;
            }
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        setEventLeadSource: function setEventLeadSource(leadSource) {
            if (this.selectedLeadSource !== leadSource) {
                this.event.lead_source_open_text = "";
            }
            this.selectedLeadSource = leadSource;
            this.event.lead_source = leadSource.label;
            if (this.selectedLeadSource.label !== 'Unknown') {
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceProTip, {show: false});
                this.shouldShowLeadSourceStars = false;
            }

            if (this.event.lead_source !== 'Other') {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.change_lead_source_in_workspace, {
                    lead_source: this.event.lead_source,
                    app_path: 'workspace'
                });
            }

            this.saveChanges('event lead source');
            this.calcLeadSourceOpenTextPlaceholder();
            this.showLeadSourceAdd();
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        sortedEventTypes: function sortedEventTypes() {
            return this.sortedList(this.eventTypes);
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        calcLeadSourceOpenTextPlaceholder: function calcLeadSourceOpenTextPlaceholder() {
            if (this.selectedLeadSource.label === "Other") {
                this.leadSourceOpenTextPlaceholder = this.$translate.instant('PROJECT.LABELS._LEAD_SOURCE_OTHER_');
            } else {
                this.leadSourceOpenTextPlaceholder = this.$translate.instant('PROJECT.LABELS._LEAD_SOURCE_REFERRAL_');
            }
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        showLeadSourceAdd: function showLeadSourceAdd() {
            this.shouldShowLeadSourceAdd = this.selectedLeadSource.label === "Other" && !this.leadSourceAdClickedNo && this.leadSources.length <= this.leadSourcesLimit;
            this.leadSourceAdClickedNo = false;
            this.shouldShowLeadSourceError = false;
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        shouldShowLeadSourcesEditedToast: function shouldShowLeadSourcesEditedToast() {
            return !this.DeviceService.checkIfMobileSize() && this.currUser.isCompanyOwner() && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: true}).show;
        },

        // This is used in workspace_template.html in the lead source widget
        // Once the widget is migrated to the new workspace, this function can be removed
        _showLeadSourcesEditedToast: function _showLeadSourcesEditedToast() {
            var toastIcon      = '<span class="icon icon-hb-nx-bell"></span>';
            var toastTitle     = this.$translate.instant('LEADSOURCE.TOAST._TITLE_');
            var toastSubTitle  = this.$translate.instant('LEADSOURCE.TOAST._SUB_TITLE_');
            var toastCtaUpdateText = this.$translate.instant('LEADSOURCE.TOAST._UPDATE_NOW_');
            var toastTextIntro    = this.$translate.instant('LEADSOURCE.TOAST._YOU_CAN_');
            var toastTextReview    = this.$translate.instant('LEADSOURCE.TOAST._REVIEW_');

            var dismiss = '<a ng-click="eventVm.dissmissLeadSourceToast()" class="lead-sources-toast__close"><span class="icon icon-hb-nx-close-16"></span></a>';
            var toastCtaUpdate = '<a class="lead-sources-toast__cta" ng-click="eventVm.updateContactForm()" >' + toastCtaUpdateText + '</a>';
            var toastCtaReview = '<div>' + toastTextIntro + '<a ng-click="eventVm.reviewContactForm()" >' + toastTextReview + '</a>' + toastSubTitle + toastCtaUpdate + '</div>';
            var toastContent   = '<div class="lead-sources-toast__content">' +  '<div><strong>' + toastTitle + '</strong>' + toastCtaReview + '</div>';

            this.toastLeadSourceContent   = '<div class="lead-source__toast">' + toastIcon + toastContent + dismiss +'</div>';

            this.toastLeadSource = this.ngToast.create({
                content: this.$sce.trustAsHtml(this.toastLeadSourceContent),
                className: 'lead-source_toast alert alert-info',
                dismissOnTimeout: false,
                compileContent: this.$scope
            });

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__shown, {source: "workspace", secondary_source: this.source});
        },

        // This is used in the lead source widget toast - need to implement in react
        // Once the widget is migrated to the new workspace, this function can be removed
        reviewContactForm: function reviewEditedLeadSources() {
            this.ngToast.dismiss(this.toastLeadSource);
            this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__closed, {source: "workspace", secondary_source: this.source, contact_form: "review"});
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
        },

        // This is used in the lead source widget toast - need to implement in react
        // Once the widget is migrated to the new workspace, this function can be removed
        updateContactForm: function updateContactForm() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__closed, {source: "workspace", secondary_source: this.source, contact_form: "update"});
            this.CompaniesManager.publishContactFormsLeadSource(this.company)
                .then(function success() {
                    this.ngToast.dismiss(this.toastLeadSource);
                    this._showConfirmationSavedContactFormToast();
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
                }.bind(this));
        },

        // This is used in the lead source widget toast - need to implement in react
        // Once the widget is migrated to the new workspace, this function can be removed
        dissmissLeadSourceToast: function dissmissLeadSourceToast() {
            this.ngToast.dismiss(this.toastLeadSource);
        }

    });
}());
