(function () {
    "use strict";

    // @ngInject
    function LoginlessControllerCtor($scope, $injector, $stateParams, $state, $window,
                                     AnalyticsService, LinksManager, AppStates, _, InjectBrandingService, StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginlessController';
        AnalyticsService.trackPageView(this, 'link expiry screen');
        this._ = _;

        this.DIRECTIVE_STATES = {
            expire: 'expiredLink',
            resend: 'resendLink'
        };

        this.LinksManager = LinksManager;
        this.AppStates = AppStates;
        this.$window = $window;
        this.$state = $state;

        this.directiveState = this.DIRECTIVE_STATES.expire;

        this.vendor = $stateParams.vendor;
        this.user = $stateParams.user;
        this.linkId = $stateParams.link_id;
        this.linkToken = $stateParams.link_token;

        this.rolloutVariant = 'pending';
        StatsigService.isGateEnabled('ng2react-loginless').then(function (enabled) {
            this.rolloutVariant = enabled ? 'react' : 'angular';
        }.bind(this));

        if (!this.vendor || !this.user) {
            this.goToState(this.AppStates.root_core_resolveLink, {link_id: this.linkId, link_token: this.linkToken});
        }

        if (this.vendor && this.vendor.company) {
            InjectBrandingService.setBrandColorVars(this.vendor.company.brand_color);
            if (this.vendor.company.company_logo && this.vendor.company.company_logo.cloudinary_url && this.vendor.company.company_logo.cloudinary_url.indexOf('yourlogohere') === -1) {
                this.company_image_url = this.vendor.company.company_logo.cloudinary_url;
            }
        }
    }

    Controllers.LoginlessController = Class(Controllers.BaseController, {

        constructor: LoginlessControllerCtor,

        regenerateLink: function regenerateLink() {

            if (this.regenerateLinkInProgress) {
                return;
            }

            this.regenerateLinkInProgress = true;

            this.LinksManager.regenerateLink(this.linkId, this.linkToken)
                .then(function success(resp) {
                    this.directiveState = this.DIRECTIVE_STATES.resend;
                }.bind(this))
                .finally(function final() {
                    this.regenerateLinkInProgress = false;
                }.bind(this));
        }
    });
}());

