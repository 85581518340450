/**
 * Created by dvircohen on 05/04/2016.
 */
(function () {
    'use strict';

    Directives.FileHeaderDirective = function WorkspaceFileHeaderDirective() {

        // @ngInject
        function WorkspaceFileHeaderDirectiveControllerCtor(
            $scope, $injector, $log, NavigationService,
            UsersManager, WorkspacesManager, WorkspaceFilesManager, AnalyticsService, PreviewModeService,
            WorkspaceFileService, ModalService, AppStates, UiPersistenceService,
            OnboardingService, $timeout, $translate, $state, SetupGuideService, PopupMessageService,
            AbTestService, Enums, OnboardingBotManager, ngToast, EventsManager, ToastService, DeviceService, moment, FeaturesService, MobileAppService, UserService, ReactModalService) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'WorkspaceFileHeaderDirectiveController';

            this.WorkspaceFileService = WorkspaceFileService;
            this.AnalyticsService = AnalyticsService;
            this.WorkspacesManager = WorkspacesManager;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.ModalService = ModalService;
            this.OnboardingService = OnboardingService;
            this.AppStates = AppStates;
            this.$log = $log;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.UsersManager = UsersManager;
            this.$state = $state;
            this.PreviewModeService = PreviewModeService;
            this.SetupGuideService = SetupGuideService;
            this.OnboardingBotManager = OnboardingBotManager;
            this.Enums = Enums;
            this.ngToast = ngToast;
            this.ToastService = ToastService;
            this.PopupMessageService = PopupMessageService;
            this.DeviceService = DeviceService;
            this.NavigationService = NavigationService;
            this.FeaturesService = FeaturesService;
            this.AbTestService = AbTestService;
            this.moment = moment;
            this.MobileAppService = MobileAppService;
            this.UserService = UserService;
            this.ReactModalService = ReactModalService;

            // this.showBreadcrumbs = true;
            this.EventsManager = EventsManager;
            
            this.isAndroid = this.DeviceService.isAndroid();
            this.user = this.UsersManager.getCurrUser();
            this.workspaceLoaded = false;
            this.achPushShown = false;
            this.workspaceParams = {};
            this.isShowCanceledFileStatus = true;
            //patch. need to change it to isOwner EVERYWHERE

            this.shouldShowSavingIndicator = this.workspaceFile.isQuestionnaire();
            this.showPrintAndVersionToggle = false;
            this.showExpirationIntro = true;
            this.UiPersistenceService = UiPersistenceService;
            this.OnboardingService = OnboardingService;
            this.userIsSignee = this.workspaceFile.isUserSignee(this.user);
            this.navigationLocation = this.AppStates.root_core_navigation_event_workspace_feed;
            this.isInAppBrowser = this.DeviceService.isInAppBrowser() && !this.workspaceFile.isTimeline();

            this.isTeamMemberForFileStatus = this.user.isVendor() && this.workspaceFile.owner.isMyTeamMember();

            this.isTeamMemberMode = this.isTeamMemberMode();

            this.isSendFilePreviewMode = this.PreviewModeService.isInSendFilePreviewMode();
            this.showSendPreviewFile = false;
            this.alreadyGotFile = false;
            this.animateSendFilePreview();

            this.register(this.workspaceFile, 'success', function getWorkspaceFile() {
                //this.setOwnerMode();
                this.file_title = this.workspaceFile.file_title;
                this.file_version = this.workspaceFile.file_version;
                this.userIsSignee = this.workspaceFile.isUserSignee(this.user);
                this.getFileTypeLabel();
                this.workspaceLoaded = true;

                if(!this.alreadyGotFile) {
                    this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id, true, false);
                    this.event = this.EventsManager.getEvent(this.workspaceFile.event._id, false, false);
                    this.isClientPortalExposed = this.workspaceFile.event.is_client_portal_exposed;

                    var gotWorkspace = function () {
                        if (this.OnboardingBotManager.isOnboardingBotActiveOnWS(this.workspace)){
                            this.navigationLocation = this.AppStates.root_core_navigation_event_workspace_feed;
                        }
                        this.client = this.workspace.getVendorsForRequestProposal(this.user._id)[0];
                        if (this.workspaceFile.isDirectPayment() && !this.firstMemberName) {
                            this.firstMemberName = this.client.full_name;
                        }
                        if (this.isLoggedInAsClient) {
                            this.loggedInAsClientClientText = this.$translate.instant('FILE.HEADER._CLIENT_MODE_CLIENT_BAR_', {
                                client_email: this.user.email
                            });
                            this.loggedInAsClientVendorText = this.$translate.instant('FILE.HEADER._CLIENT_MODE_VENDOR_BAR_', {
                                vendor_email: this.workspaceFile.owner.full_name
                            });
                            this.isLoggedInAsClientMode = true;
                        }
                    }.bind(this);

                    if (this.workspace.wasFullyFetched()) {
                        gotWorkspace()
                    } else {
                        this.register(this.workspace, 'success', gotWorkspace());
                    }

                    this.workspaceFileParams = {
                        workspace_id: this.workspaceFile.couple_card_id,
                        event_id: this.workspaceFile.event._id
                    };

                    if (this.workspaceLoaded) {
                        this._shouldShowToastTemplatesFileCreated();
                    }

                    this.clientPreviewModalOnLoad();
                }

                this.alreadyGotFile = true;

            }.bind(this));

            if (angular.isFunction(this.sendFilePreviewAction)) {
                this.showSendPreviewFile = this.WorkspaceFileService.shouldShowSendFilePreview(this.workspaceFile);
            }

            // Go to preview
            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._GO_TO_CLIENT_VIEW_, function () {
                if (!this.isPreviewMode && !this.showSendPreviewFile) {
                    $scope.$applyAsync(function () {
                        this.updatePreviewMode();
                    }.bind(this));
                }
            }.bind(this));

            // exit preview
            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._EXIT_CLIENT_VIEW_, function () {
                if (this.showVendorPreviewBar() && !(this.PreviewModeService && this.PreviewModeService.inSendFilePreviewMode)) {
                    $scope.$applyAsync(function () {
                        this.goBackToEditFile();
                    }.bind(this));
                }
            }.bind(this));

            $scope.$on('$destroy', function () {
                /*if (this.isPreviewMode) {
                    PreviewModeService.quit();
                }*/
            }.bind(this));
        }

        var WorkspaceFileHeaderDirectiveController = Class(Controllers.BaseController, {
            constructor: WorkspaceFileHeaderDirectiveControllerCtor,

            //setOwnerMode: function setOwnerMode() {
            //    this.isVendor = this.workspaceFile.isOwnerMode();
            //    this.isOwner = this.isVendor;
            //    this.isClient = !this.workspaceFile.isOwnerMode();
            //},

            isTeamMemberMode: function isTeamMemberMode() {
                return this.workspaceFile.isModeratorMode();
            },

            updatePreviewMode: function updatePreviewMode() {
                if (this.isPreviewMode){
                    this.previewModeOff();
                    return;
                }

                if (this.DeviceService.navLargeBreakPoint()) {
                    this.previewMode();
                    return;
                }

                this.WorkspaceFileService.shouldShowClientPreviewModal() ? this.clientPreviewModal() : this.previewMode();

            },


            showAchPushToast: function showAchPushToast() {
                var achShown = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showAchPushToast, { count: 0 }).count;
                if ((this.workspaceFile.isProposal() || this.workspaceFile.isInvoice()) &&
                    this.workspaceFile.getTotalProposalValue() > 500 &&
                    this.workspaceFile.accepted_payment_method !== 'bank_account' &&
                    this.workspaceFile.isOwnerMode() &&
                    this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showAchPushToastLastFileId, { file_id: null}).file_id !== this.workspaceFile._id &&
                    achShown < 3) {

                        this.$timeout(function timeout() {
                            this.achPushToastId = this.ToastService.showAchPushToast();
                            achShown +=1;
                            if (achShown == 1) {
                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.select_toast_show);
                            }
                            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showAchPushToast, { count: achShown });
                            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showAchPushToastLastFileId, { file_id: this.workspaceFile._id });
                        }.bind(this), 10);
                }
            },

            clientPreviewModalOnLoad: function clientPreviewModalOnLoad() {
                var params = this.$state.params;
                if (this.isPreviewMode &&  params.showClientPreviewModal) {
                    this.clientPreviewModal();
                }
            },

            clientPreviewModal: function clientPreviewModal() {
                this.WorkspaceFileService.clientPreviewModalIfNeeded(this.workspaceFile.file_type)
                    .finally( function () {
                        this.previewMode();
                        this.shouldShowClientFlowModal = false;
                    }.bind(this))
            },

            previewMode: function previewMode() {
                this.isPreviewMode = true;

                if (angular.isFunction(this.updateShowingVersionNotification)) {
                    this.updateShowingVersionNotification();
                }

                this.PreviewModeService.enter(this.workspaceFile, this.$state.params);
            },

            previewModeOff: function previewModeOff() {
                this.isPreviewMode = false;

                if (angular.isFunction(this.updateShowingVersionNotification)) {
                    this.updateShowingVersionNotification();
                }
            },

            onEditFile: function onEditFile() {

                if (!this.canEditRecurringFile()) {
                    var popupText = {
                        translationId: 'FILE.HEADER._CANT_EDIT_RECURRING_FILE_',
                        interpolateParams: {fileType: this.workspaceFile.fileType}
                    };

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, popupText, null, 'FREQUENT_BUTTONS._OK_');
                    return;
                }

                // this is where we should call the server's API to start editing the file.
                // show a progress? or not just allow editing call the server and see in the response if we are ok if not show
                this.workspaceFile.createFileDraft().then(function () {
                    this.isEditableMode = this.workspaceFile.isEditable();
                    this.loadVersion({is_draft: true});
                }.bind(this));
            },

            canEditRecurringFile: function canEditRecurringFile() {
                return !(this.workspaceFile.isRecurringPayment() && (this.workspaceFile.isSent() || this.workspaceFile.hasPaidPayments()));
            },

            printWorkspaceFile: function printWorkspaceFile() {
                this.hidePrintDropDown();
                this.WorkspaceFileService.printWorkspaceFile(this.workspaceFile);
            },


            downloadAsPdf: function downloadAsPdf() {
                this.hidePrintDropDown();
                this.WorkspaceFileService.downloadAsPdfFile(this.workspaceFile);
            },

            showPrintDropDown: function showPrintDropDown(){
                this.isPrintDropdownVisible = true;
            },

            hidePrintDropDown: function hidePrintDropDown(){
                this.isPrintDropdownVisible = false;
            },

            getFileCardClasses: function getFileCardClasses() {
                return this.WorkspaceFileService.getFileCardClasses(this.workspaceFile);
            },

            loadVersion: function loadVersion(version) {
                var versionObj = null;
                if (!this.workspaceFile.isLatestVersion(version.version) || (!this.isOwner && !this.workspaceFile.hasPaidPayments())) {
                    versionObj = version.version;
                }

                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile, versionObj);
            },

            saveWorkspaceFileAttribute: function saveWorkspaceFileAttribute() {

                if (this.file_title.length === 0) {
                    this.file_title = this.workspaceFile.file_title;
                    return;
                }
                this.workspaceFile.file_title = this.file_title;
                this.WorkspaceFilesManager.saveWorkspaceFile(this.workspaceFile);
            },

            showActionButton: function showActionButton() {
                return (!this.workspaceFile.isPaymentsSeen() && !this.workspaceFile.isCanceled()) || this.PreviewModeService.isInPreviewMode();
            },

            showContinueToAgreement: function showContinueToAgreement() {
                if (!this.workspaceFile.isAgreement() && this.workspaceFile.hasAgreement()) {
                    if (this.PreviewModeService.isInPreviewMode()) {
                        return this.PreviewModeService.inState('proposal');
                    }

                    return !this.workspaceFile.isAgreementSeen();
                }

                return false;
            },

            showSignAgreement: function showSignAgreement() {
                return this.workspaceFile.hasAgreement() && this.workspaceFile.isAgreementSeen() && !this.workspaceFile.isAgreementSigned() &&
                    this.userIsSignee && !this.PreviewModeService.isInPreviewMode() && this.workspaceFile.isLatestVersion();
            },

            showContinueToPayment: function showContinueToPayment() {

                if (!this.workspaceFile.isLatestVersion()) {
                    return false;
                }
                if (this.workspaceFile.hasPayments() && this.workspaceFile.getTotalProposalValue() > 0) {
                    if (this.PreviewModeService.isInPreviewMode()) {
                        return this.workspaceFile.isProposal() ? this.PreviewModeService.inState('agreement') : this.PreviewModeService.inState('proposal');
                    }

                    return ((this.workspaceFile.hasAgreement() && this.workspaceFile.isAgreementSigned()) || this.workspaceFile.isInvoice()) && !this.workspaceFile.hasOnlyNotCompletedMilestonePayments();
                }

                return false;
            },

            showExploreHoneyBook: function showExploreHoneyBook() {
                return this.workspaceFile.file_title === "HoneyBook Example Project Proposal" &&
                       this.workspaceFile.status === "payments_seen";
            },

            showSubmitQuestionnaire: function showSubmitQuestionnaire() {
                return this.workspaceFile.isQuestionnaire() && !this.workspaceFile.isComplete();
            },

            showSubmitBrochure: function showSubmitBrochure() {
                return this.workspaceFile.isBrochure();
            },

            hasNotYetSentFirstFile: function hasNotYetSentFirstFile() {
                return this.OnboardingService.hasNotYetSentFirstFile();
            },

            completeClientLogin: function completeClientLogin() {
                this.UserService.bookInPersonLogout(this.AppStates.root_core_workspaceFile, { workspace_file_id: this.workspaceFile._id });
            },

            showAsideDrawer: function showAsideDrawer() {
                this.appState.asideDrawerOpen = !this.appState.asideDrawerOpen;
            },

            getFileTypeLabel: function getFileTypeLabel() {
                this.fileTypeLabel = this.WorkspaceFileService.formatFileTypeToDisplayName(this.workspaceFile.file_type, true);
            },

            stopPreviewMode: function stopPreviewMode() {
                this.PreviewModeService.returnToFile(this.workspaceFile, this.$state.params);
            },

            _cameFromTemplatesEditor: function _cameFromTemplatesEditor() {
                return this.NavigationService.previousStateWas(this.AppStates.root_core_navigation_templatesEditor)
            },

            _shouldShowToastTemplatesFileCreated: function _shouldShowToastTemplatesFileCreated() {
                if (!!this.$state.previous) {
                    if (this._cameFromTemplatesEditor() && !this.isPreviewMode) {
                        this._showFileFromTemplateToast();
                        this.workspaceFileParams.origin = [this.Enums.createFileOriginType.template, this.workspaceFile.file_type];
                    }
                }
            },

            _showFileFromTemplateToast: function _showFileFromTemplateToast() {
                this.$timeout(function () {
                    this.ToastService.showSuccess({
                        contentTranslation: 'TEMPLATES.TOAST._SUB_TITLE_',
                        titleTranslation: 'TEMPLATES.TOAST._TITLE_',
                        iconCssClass: 'icon icon-hb-nx-check',
                        dismissOnTimeout: true,
                        timeout:5000
                    });
                }.bind(this), 1000);
            },

            backToWorkSpace: function backToWorkSpace(){
                this.NavigationService.setBackToState({ name: this.AppStates.root_core_navigation_pipeline })
                this.$state.go(this.navigationLocation, this.workspaceFileParams);
            },

            goBack: function goBack() {
                this.NavigationService.goHistoryBack();
            },

            goBackToEditFile: function goBackToEditFile() {
                //shouldExplicitlyExitPreviewtoEditFile was set when we entered previewMode.
                // //if true - we probably got directly to preview, so need to explicitely go to the file edit.

                // for brochure / questionnaire / timeline - just exit preview mode
                if(this.workspaceFile.isBrochure() || this.workspaceFile.isQuestionnaire() || this.workspaceFile.isTimeline()) {
                    this.updatePreviewMode();
                } else if (this.PreviewModeService.shouldExplicitlyExitPreviewtoEditFile) {
                    this.PreviewModeService.returnToFile(this.workspaceFile, this.$state.params, true);
                } else {
                    this.goBack();
                }
            },

            showVendorPreviewBar: function showVendorPreviewBar() {
                return (this.PreviewModeService.isInPreviewMode()) ||
                    (this.isOwnerMode && this.isPreviewMode);
            },


            toggleAsideSettingsDrawer: function toggleAsideSettingsDrawer() {
                this.AnalyticsService.trackClick(this, (!this.appState.asideDrawerOpen ? 'expand' : 'collapse') + ' nav settings');
                this.appState.asideDrawerOpen =  !this.appState.asideDrawerOpen;
            },

            isBrochureNew: function isBrochureNew() {
                return this.WorkspaceFileService.isNewBrochure(this.workspaceFile);
            },

            animateSendFilePreview: function animateSendFilePreview() {
                var userWasCreatedAfterAnimation =
                    this.FeaturesService.wasCreatedAfterFeature(
                        this.user,
                        this.FeaturesService.features.sendTestFileAnimation
                    );

                var hasAnimated = this.UiPersistenceService.getUiPersistence(
                    this.UiPersistenceService.keys.animateSendTestFileButton,
                    { hasAnimated: false }
                )['hasAnimated'];

                if (userWasCreatedAfterAnimation && !hasAnimated) {
                    this.isAnimatingSendFilePreview = true;
                    this.UiPersistenceService.setUiPersistence(
                        this.UiPersistenceService.keys.animateSendTestFileButton,
                        { hasAnimated: true }
                    );
                }
            },

        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/workspace_file/file_header/file_header_directive_template.html',
            controller: WorkspaceFileHeaderDirectiveController,
            controllerAs: 'workspaceFileHeaderVm',
            bindToController: true,

            scope: {
                workspaceFile: '=',
                workspace: '=',
                isOwnerMode: '=',
                isClientMode: '=',
                isPreviewMode: '=',
                isEditableMode: '=',
                isViewOnlyMode: '=',
                isLoggedInAsClient: '=',
                sendFileText: '=',
                loading: '=',

                sendFileAction: '&?',
                sendFilePreviewAction: '&?',
                resendFileAction: '&?',
                discardDraftAction: '&',

                // First file cleanup
                isFirstFileNotSent: '=',

                // Versioned files
                updateShowingVersionNotification: '&?',
                notificationTranslationData: '@?',

                // Booked files & steps
                signAgreementAction: '&?',
                continueToAgreementAction: '&?',
                continueToPaymentAction: '&?',
                exploreHoneyBookAction: '&?',

                // Questionnaire
                submitQuestionnaireAction: '&?',

                // Brochure
                submitBrochureAction: '&?',
                isSubmitBrochureEnabled: '&?',

                isHideFileHeader: '=',
            }
        };
    };

}());


